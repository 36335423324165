import {
  Box,
  Button,
  Modal,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import "./damage.css";
import { db } from "../../firebase-config";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import avatar from "../../assets/ava-driver.png";
import { FaCarBurst } from "react-icons/fa6";
import { IoIosCloudDone } from "react-icons/io";
import { GoLaw } from "react-icons/go";

const DamageList = () => {
  const usersCollectionRef = collection(db, "damages");
  const [drivers, setDrivers] = useState([]);
  const [activeTab, setActiveTab] = useState("active"); // default значение вкладки - "Активные"
  const [filter, setFilter] = useState(null); // Начальное значение фильтра — null
  const [statusUpdateSuccess, setStatusUpdateSuccess] = useState(false);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    const data = await getDocs(query(usersCollectionRef));
    setDrivers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const deleteDriver = async (id) => {
    const userDoc = doc(db, "drivers", id);
    await deleteDoc(userDoc);
    if (deleteDriver) {
      handleOpen();
    } else {
      alert("ошибка загрузки");
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const fetchTransactions = async () => {
    const q = query(collection(db, "damages"));
    const querySnapshot = await getDocs(q);
    let totals = {};
    querySnapshot.forEach((doc) => {
      const transactions = doc.data().transactions || [];
      const totalAmount = transactions.reduce(
        (sum, transaction) => sum + parseFloat(transaction.amountNew || 0),
        0
      );
      totals[doc.id] = totalAmount;
    });
    return totals;
  };

  const [totalAmounts, setTotalAmounts] = useState({});

  useEffect(() => {
    const loadData = async () => {
      const totals = await fetchTransactions();
      setTotalAmounts(totals);
    };
    loadData();
  }, []);

  function formatNumber(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  const filteredDrivers = drivers.filter(
    (driver) =>
      formatNumber(driver.amount) !== formatNumber(totalAmounts[driver.id] || 0)
  );
  const archivedDrivers = drivers.filter(
    (driver) =>
      formatNumber(driver.amount) === formatNumber(totalAmounts[driver.id] || 0)
  );

  // Подсчет количества записей в каждой категории
  const allCount = drivers.length;
  const activeCount = drivers.filter(
    (driver) => driver.status === "active"
  ).length;
  const endCount = drivers.filter((driver) => driver.status === "end").length;

  // Фильтрация на основе выбранного таба и дополнительных фильтров
  const filteredList = () => {
    // Сначала фильтруем по активному/неактивному статусу
    let result;

    if (activeTab === "all") {
      // Показываем все записи
      result = drivers;
    } else if (activeTab === "active") {
      // Только активные записи
      result = drivers.filter((driver) => driver.status === "active");
    } else {
      // Только завершенные записи
      result = drivers.filter((driver) => driver.status === "end");
    }

    // Затем применяем дополнительные фильтры
    if (filter === "osago") {
      return result.filter((driver) => driver.classeDamage === "Страховой");
    } else if (filter === "non-insured") {
      return result.filter(
        (driver) =>
          formatNumber(driver.amount) !==
          formatNumber(totalAmounts[driver.id] || 0)
      );
    } else if (filter === "archived") {
      return result.filter(
        (driver) =>
          formatNumber(driver.amount) ===
          formatNumber(totalAmounts[driver.id] || 0)
      );
    }
    return result;
  };

  const toggleFilter = (selectedFilter) => {
    setFilter((prevFilter) =>
      prevFilter === selectedFilter ? null : selectedFilter
    );
  };

  // Функция для обновления статуса
  const updateStatus = async (id, newStatus) => {
    try {
      const damageRef = doc(db, "damages", id);
      await updateDoc(damageRef, {
        status: newStatus,
      });

      // Обновляем данные в локальном состоянии
      setDrivers(
        drivers.map((driver) =>
          driver.id === id ? { ...driver, status: newStatus } : driver
        )
      );

      // Показываем сообщение об успешном обновлении
      setStatusUpdateSuccess(true);
      setTimeout(() => {
        setStatusUpdateSuccess(false);
      }, 2000);
    } catch (error) {
      console.error("Ошибка при обновлении статуса:", error);
      alert("Не удалось обновить статус. Попробуйте еще раз.");
    }
  };

  return (
    <div className="grid">
      <Header />
      <div className="content">
        <p className="header-mt f-roboto-900">ДТП / Повреждения</p>

        {/* Фильтры */}
        <div className="box-damage-container">
          <div
            className={`box-damage-block ${filter === "osago" ? "active" : ""}`}
            onClick={() => toggleFilter("osago")}
          >
            <GoLaw size="30" className="filter-icon-box" />
            <div>
              <p className="t-header-filter">По ОСАГО</p>
              <p className="t-mini-filter">Cтраховые случаи</p>
            </div>
          </div>
          {/*
          <div
            className={`box-damage-block ${filter === "osago" ? "active" : ""}`}
            onClick={() => toggleFilter("osago")}
          >
            <GoLaw size="30" className="filter-icon-box" />
            <div>
              <p className="t-header-filter">По ОСАГО, суды</p>
              <p className="t-mini-filter">ОСАГО, судебные дела</p>
            </div>
          </div>
          */}
          <div
            className={`box-damage-block ${
              filter === "non-insured" ? "active" : ""
            }`}
            onClick={() => toggleFilter("non-insured")}
          >
            <FaCarBurst size="30" className="filter-icon-box" />
            <div>
              <p className="t-header-filter">По вине водителя</p>
              <p className="t-mini-filter">ДТП, повреждения</p>
            </div>
          </div>
          <div
            className={`box-damage-block ${
              filter === "archived" ? "active" : ""
            }`}
            onClick={() => toggleFilter("archived")}
          >
            <IoIosCloudDone size="30" className="filter-icon-box" />
            <div>
              <p className="t-header-filter">Архив</p>
              <p className="t-mini-filter">Закрытые дела</p>
            </div>
          </div>
        </div>

        <div className="info-damage-block">
          <div
            style={{
              backgroundColor: "#f5f5f5",
              borderRadius: "16px",
            }}
          >
            <div
              style={{
                display: "inline-flex",
                backgroundColor: "white",
                borderRadius: "12px",
                padding: "8px",
                boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
              }}
            >
              <button
                style={{
                  padding: "12px 32px",
                  borderRadius: "12px",
                  marginRight: "12px",
                  fontSize: "16px",
                  fontWeight: activeTab === "active" ? 500 : 400,
                  backgroundColor:
                    activeTab === "active" ? "#f5f5f5" : "transparent",
                  color: activeTab === "active" ? "#333333" : "#888888",
                  border: "none",
                  cursor: "pointer",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => setActiveTab("active")}
              >
                Активные
                <span
                  style={{
                    backgroundColor: "#007AFF",
                    color: "white",
                    borderRadius: "10px",
                    fontSize: "12px",
                    padding: "4px 4px",
                    textAlign: "center",
                    marginLeft: "5px",
                  }}
                >
                  {activeCount}
                </span>
              </button>
              <button
                style={{
                  padding: "12px 32px",
                  borderRadius: "12px",
                  fontSize: "16px",
                  fontWeight: activeTab === "end" ? 500 : 400,
                  backgroundColor:
                    activeTab === "end" ? "#f5f5f5" : "transparent",
                  color: activeTab === "end" ? "#333333" : "#888888",
                  border: "none",
                  cursor: "pointer",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => setActiveTab("end")}
              >
                Завершенные
                <span
                  style={{
                    backgroundColor: "#007AFF",
                    color: "white",
                    borderRadius: "10px",
                    fontSize: "12px",
                    padding: "4px 4px",
                    textAlign: "center",
                    marginLeft: "5px",
                  }}
                >
                  {endCount}
                </span>
              </button>
            </div>
          </div>
          <div className="drivers-block-btn"></div>
          <Button
            variant="outlined"
            size="small"
            className="btn-add-to-damage"
            as={Link}
            to={"/add_damage"}
            style={{
              padding: "7px 15px !important",
              color: "white !important",
              backgroundColor: "#436cff !important",
              textTransform: "capitalize !important",
              width: "100px !important",
              textAlign: "center !important",
              borderRadius: "10px !important",
              transition: "0.3s !important",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
            }}
          >
            Добавить
          </Button>
        </div>
        <table id="myTable">
          <thead>
            <tr>
              <th>Ф.И.О. водителя</th>
              <th>Гос.номер</th>
              <th className="center-table f-roboto-500b">Тип повреждения</th>
              <th className="center-table f-roboto-500b">Виновность</th>
              <th className="center-table f-roboto-500b">Сумма ущерба</th>
              <th className="center-table">Сколько выплатил</th>
              <th className="center-table">Статус</th>
              <th className="center-table">Комментарий</th>
              <th className=" th-right">Действия</th>
            </tr>
          </thead>
          <tbody>
            {filteredList().map((driver) => (
              <tr key={driver.id} className="fonts-table-style">
                <td className="f-roboto-500">{driver.driver}</td>
                <td className="f-roboto-500">{driver.auto}</td>
                <td className="center-table f-roboto-500">
                  {driver.typeDamage}
                </td>
                <td className="center-table f-roboto-500">{driver.guilt}</td>
                <td className="rubles-simbol center-table f-roboto-500">
                  <b>{formatNumber(driver.amount)} ₽</b>
                </td>
                <td className="rubles-simbol center-table f-roboto-500">
                  {formatNumber(totalAmounts[driver.id] || 0)} ₽
                </td>
                <td className="center-table f-roboto-500">
                  <FormControl size="small" style={{ minWidth: 120 }}>
                    <Select
                      value={driver.status || ""}
                      onChange={(e) => updateStatus(driver.id, e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor:
                            driver.status === "active"
                              ? "#4caf50"
                              : driver.status === "end"
                              ? "#f44336"
                              : "#bdbdbd",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor:
                            driver.status === "active"
                              ? "#2e7d32"
                              : driver.status === "end"
                              ? "#d32f2f"
                              : "#757575",
                        },
                        color:
                          driver.status === "active"
                            ? "#4caf50"
                            : driver.status === "end"
                            ? "#f44336"
                            : "inherit",
                      }}
                    >
                      <MenuItem value="">
                        <em>Выберите</em>
                      </MenuItem>
                      <MenuItem value="active">Активный</MenuItem>
                      <MenuItem value="end">Завершен</MenuItem>
                    </Select>
                  </FormControl>
                </td>
                <td className="comment-table f-roboto-500">{driver.comment}</td>
                <td className="end-actions-table">
                  <Link to={`/damage_view/${driver.id}`}>
                    <Button
                      variant="none"
                      size="small"
                      className="btn-icon-table"
                    >
                      <VisibilityIcon className="icon-table" />
                    </Button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Модальное окно успешного обновления статуса */}
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#4caf50",
            color: "white",
            padding: "10px 20px",
            borderRadius: "4px",
            boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
            display: statusUpdateSuccess ? "flex" : "none",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <CheckCircleOutlineIcon />
          <span>Статус успешно обновлен</span>
        </div>

        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style} className="box-modal-succes">
            <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
              <HighlightOffIcon className="modal-grid-delete-icon" />
              <p className="modal-grid-succes-text">Водитель успешно удален!</p>
            </Typography>
            <a href="/drivers" className="modal-grid-succes-text-a">
              Вернуться в раздел "Водители"
            </a>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default DamageList;
