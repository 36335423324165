import {
  Box,
  Breadcrumbs,
  Button,
  Modal,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Drawer,
  CircularProgress,
  Alert,
} from "@mui/material";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import "./drivers.css";
import { db } from "../../firebase-config";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import avatar from "../../assets/ava-driver.png";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";

const Drivers = () => {
  const usersCollectionRef = collection(db, "drivers");
  const [drivers, setDrivers] = useState([]);
  const [printDrawerOpen, setPrintDrawerOpen] = useState(false);
  const [cars, setCars] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [showDoneMessage, setShowDoneMessage] = useState(false);
  const [selectedDriverId, setSelectedDriverId] = useState("");
  const [selectedDriverData, setSelectedDriverData] = useState(null);
  const [printData, setPrintData] = useState({
    carId: "",
    carrierId: "",
    startDate: "",
    endDate: "",
    rentalAmount: "",
    deposit: "",
  });

  const updateUser = async (id, age) => {
    const userDoc = doc(db, "drivers", id);
    const newFields = { age: age + 1 };
    await updateDoc(userDoc, newFields);
  };

  const deleteUser = async (id) => {
    const userDoc = doc(db, "drivers", id);
    await deleteDoc(userDoc);
  };

  useEffect(() => {
    const getUsers = async () => {
      //  const data = await getDocs(usersCollectionRef);
      const data = await getDocs(query(usersCollectionRef, orderBy("surname")));
      setDrivers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    getUsers();
  }, []);

  const deleteDriver = async (id) => {
    const userDoc = doc(db, "drivers", id);
    await deleteDoc(userDoc);
    if (deleteDriver) {
      handleOpen();
    } else {
      alert("ошибка загрузки");
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const start = () => {
    if (drivers.value !== "") {
      return (
        <div>
          <p>Нет</p>
        </div>
      );
    } else {
      //Else not set CSS rule
    }
  };

  const date = new Date();

  // Получение всех документов из коллекции "auto"
  const getAllDocuments = async () => {
    const autoCollectionRef = collection(db, "auto");
    const querySnapshot = await getDocs(autoCollectionRef);
    const documents = [];

    querySnapshot.forEach((doc) => {
      documents.push({ id: doc.id, data: doc.data() });
    });

    return documents;
  };

  // Функция для загрузки автомобилей
  const fetchCars = async () => {
    try {
      const carsCollection = collection(db, "auto");
      const carsSnapshot = await getDocs(carsCollection);
      const carsList = carsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCars(carsList);
    } catch (error) {
      console.error("Ошибка загрузки автомобилей:", error);
    }
  };

  // Функция для загрузки перевозчиков
  const fetchCarriers = async () => {
    try {
      const carriersCollection = collection(db, "carriers");
      const carriersSnapshot = await getDocs(carriersCollection);
      const carriersList = carriersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCarriers(carriersList);
    } catch (error) {
      console.error("Ошибка загрузки перевозчиков:", error);
    }
  };

  // Функция для обработки изменений в полях печати
  const handlePrintDataChange = (e) => {
    const { name, value } = e.target;
    setPrintData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Если изменился ID автомобиля или перевозчика, найдем соответствующий объект
    if (name === "carId") {
      const car = cars.find((c) => c.id === value);
      setSelectedCar(car);
    } else if (name === "carrierId") {
      const carrier = carriers.find((c) => c.id === value);
      setSelectedCarrier(carrier);
    } else if (name === "driverId") {
      setSelectedDriverId(value);
      // Загрузить данные выбранного водителя
      fetchDriverDetails(value);
    }
  };

  // Функция для загрузки данных водителя
  const fetchDriverDetails = async (driverId) => {
    try {
      if (!driverId) {
        setSelectedDriverData(null);
        return;
      }

      const driverDocRef = doc(db, "drivers", driverId);
      const driverDocSnap = await getDoc(driverDocRef);

      if (driverDocSnap.exists()) {
        const data = driverDocSnap.data();
        setSelectedDriverData(data);
      } else {
        setSelectedDriverData(null);
      }
    } catch (error) {
      console.error("Ошибка загрузки данных водителя:", error);
      setSelectedDriverData(null);
    }
  };

  // Функция для открытия панели печати
  const handleOpenPrintDrawer = () => {
    setPrintDrawerOpen(true);
    fetchCars();
    fetchCarriers();
    setShowDoneMessage(false);
    setPrintData({
      carId: "",
      carrierId: "",
      startDate: "",
      endDate: "",
      rentalAmount: "",
      deposit: "",
      driverId: "",
    });
  };

  // Функция для формирования текста с данными для вставки в договор
  const generateContractData = () => {
    if (!selectedDriverData || !selectedCar || !selectedCarrier) return "";

    // Форматирование даты
    const formatDate = (dateString) => {
      if (!dateString) return "";
      const date = new Date(dateString);
      return date.toLocaleDateString("ru-RU", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    };

    // Форматирование ФИО
    const driverFullName = `${selectedDriverData.surname || ""} ${
      selectedDriverData.name || ""
    } ${selectedDriverData.patronymic || ""}`.trim();

    return `
  === ДАННЫЕ ДЛЯ ДОГОВОРА ===
  
  ВОДИТЕЛЬ:
  ФИО: ${driverFullName}
  Паспорт: ${selectedDriverData.passportNumber || ""}
  Выдан: ${selectedDriverData.issuedBy || ""} ${
      formatDate(selectedDriverData.issueDate) || ""
    }
  Код подразделения: ${selectedDriverData.divisionCode || ""}
  Адрес: ${selectedDriverData.addressRegistered || ""}
  Телефон: ${selectedDriverData.phone || ""}
  Вод. удостоверение: ${selectedDriverData.licenseNumber || ""}
  
  АВТОМОБИЛЬ:
  Гос. номер: ${selectedCar.gos || ""}
  Модель: ${selectedCar.model || ""}
  VIN: ${selectedCar.vin || ""}
  
  ПЕРЕВОЗЧИК:
  Название: ${selectedCarrier.name || ""}
  
  УСЛОВИЯ ДОГОВОРА:
  Дата начала: ${formatDate(printData.startDate)}
  Дата окончания: ${formatDate(printData.endDate)}
  Сумма аренды: ${printData.rentalAmount || "0"} руб.
  Депозит: ${printData.deposit || "0"} руб.
  `;
  };

  // Функция для скачивания шаблона договора
  const handlePrintContract = async () => {
    try {
      setIsPrinting(true);

      // Проверка наличия обязательных данных
      if (
        !printData.driverId ||
        !printData.carId ||
        !printData.carrierId ||
        !printData.startDate ||
        !printData.endDate
      ) {
        throw new Error("Пожалуйста, заполните все обязательные поля");
      }

      // Копируем данные для вставки в буфер обмена
      const contractData = generateContractData();
      await navigator.clipboard.writeText(contractData);

      // Скачиваем и обрабатываем шаблон договора
      try {
        // Импортируем шаблон напрямую через require
        const templatePath = require("../../templates/tempate-dogovor-driver.docx");

        const response = await fetch(templatePath);

        if (!response.ok) {
          throw new Error(`Ошибка загрузки шаблона (${response.status})`);
        }

        // Получаем файл как ArrayBuffer вместо Blob
        const arrayBuffer = await response.arrayBuffer();

        if (!arrayBuffer || arrayBuffer.byteLength === 0) {
          throw new Error("Получен пустой файл шаблона");
        }

        // Форматирование полного ФИО
        const driverFullName = `${selectedDriverData.surname || ""} ${
          selectedDriverData.name || ""
        } ${selectedDriverData.patronymic || ""}`.trim();

        // Форматирование даты
        const formatDate = (dateString) => {
          if (!dateString) return "";
          const date = new Date(dateString);
          return date.toLocaleDateString("ru-RU", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });
        };

        // Используем PizZip для обработки docx как zip-архива
        const zip = new PizZip(arrayBuffer);
        const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
        });

        // Заполняем шаблон данными, используя точно такие же имена полей как в шаблоне
        doc.setData({
          // Основные поля из шаблона
          driverFullName: driverFullName,
          carGos: selectedCar?.gos || "",
          carMarka: selectedCar?.marka || "",
          carModel: selectedCar?.model || "",
          carVin: selectedCar?.vin || "",
          carColor: selectedCar?.color || "",
          carYear: selectedCar?.year || "",
          startDate: formatDate(printData.startDate),
          endDate: formatDate(printData.endDate),
          rentalAmount: printData.rentalAmount || "",
          deposit: printData.deposit || "",

          // Дополнительные поля для полноты
          issuedBy: selectedDriverData.issuedBy || "",
          divisionCode: selectedDriverData.divisionCode || "",
          issueDate: formatDate(selectedDriverData.issueDate),
          addressRegistered: selectedDriverData.addressRegistered || "",
          phone: selectedDriverData.phone || "",
          licenseNumber: selectedDriverData.licenseNumber || "",
          passportNumber: selectedDriverData.passportNumber || "",

          // Добавляем все поля для перевозчика
          carrierName: selectedCarrier?.name || "",
          carrierTypeCompany: selectedCarrier?.typeCompany || "",
          carrierInn: selectedCarrier?.inn || "",
          carrierOgrn: selectedCarrier?.ogrn || "",
          carrierAddress: selectedCarrier?.address || "",
          carrierBill: selectedCarrier?.bill || "",
          carrierBank: selectedCarrier?.bank || "",
          carrierBikBank: selectedCarrier?.bikBank || "",
        });

        // Рендерим документ
        doc.render();

        // Генерируем итоговый файл
        const generatedBlob = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });

        // Сохраняем файл с помощью file-saver
        saveAs(
          generatedBlob,
          `Договор_аренды_${selectedDriverData.surname || "водитель"}_${
            selectedDriverData.name || ""
          }.docx`
        );

        // Показываем сообщение об успехе
        setShowDoneMessage(true);
      } catch (error) {
        console.error("Ошибка при загрузке шаблона:", error);

        // Добавляем более подробную информацию об ошибке
        if (error.properties && error.properties.errors) {
          console.error(
            "Ошибки шаблона:",
            JSON.stringify(error.properties.errors)
          );
        }

        // Если не удалось обработать docx, сообщаем о необходимости проверить шаблон
        alert(
          `Ошибка при загрузке шаблона: ${error.message}\n\nПроверьте, что файл шаблона не поврежден и находится в правильной директории.`
        );
      }

      setIsPrinting(false);
    } catch (error) {
      console.error("Ошибка при генерации договора:", error);
      alert("Ошибка: " + error.message);
      setIsPrinting(false);
    }
  };

  // Стили для панели печати
  const drawerStyle = {
    width: 350,
    padding: "20px",
  };

  return (
    <div className="grid">
      <Header />
      <div className="content">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <a
              className="breadcrumbs-first"
              href="/"
              underline="hover"
              color="inherit"
            >
              Дашборд
            </a>
            <Typography color="text.primary">
              <b>Водители</b>
            </Typography>
          </Breadcrumbs>
        </div>
        <p className="header-mt">Водители</p>

        <div className="info-active-block-table">
          <Button
            variant="outlined"
            size="small"
            className="btn-add-to-table"
            as={Link}
            to={"/add_drivers"}
            style={{ marginRight: "10px" }}
          >
            Добавить
          </Button>
          <Button
            variant="outlined"
            size="small"
            className="btn-add-to-table"
            onClick={handleOpenPrintDrawer}
            style={{ marginRight: "10px" }}
          >
            Создать договор
          </Button>
          <Button
            variant="outlined"
            size="small"
            className="btn-settings-table"
          >
            <SettingsIcon className="icon-setting" />
          </Button>
          <div className="drivers-block-btn"></div>
        </div>

        <table id="myTable">
          <tr>
            <th>Фото</th>
            <th>Фамилия</th>
            <th>Имя</th>
            <th>Отчество</th>
            <th>Телефон</th>
            <th>Вод.удост.</th>
            <th>Комментарий</th>
            <th>Действия</th>
          </tr>
          {drivers.map((drivers) => (
            <tr key={drivers.id}>
              <td>
                <img src={avatar} alt="Logo" className="ava-img" />
              </td>
              <td>{drivers.surname}</td>
              <td>{drivers.name}</td>
              <td>{drivers.patronymic}</td>
              <td>{start}</td>
              <td>{drivers.certificate}</td>
              <td>{drivers.certificate}</td>
              <td className="td-table-end-action">
                <Link to={`/drivers_view/${drivers.id}`} value={drivers.id}>
                  <Button
                    variant="none"
                    size="small"
                    className="btn-icon-table"
                  >
                    <VisibilityIcon className="icon-table" />
                  </Button>
                </Link>
                <Button
                  variant="none"
                  size="small"
                  className="btn-icon-table"
                  onClick={() => {
                    deleteDriver(drivers.id);
                  }}
                >
                  <DeleteOutlineIcon className="icon-table" />
                </Button>
              </td>
            </tr>
          ))}
        </table>
        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style} className="box-modal-succes">
            <Typography
              className="modal-grid-succes"
              id="keep-mounted-modal-description"
              sx={{ mt: 2 }}
            >
              <HighlightOffIcon className="modal-grid-delete-icon" />
              <p className="modal-grid-succes-text">Водитель успешно удален!</p>
            </Typography>
            <a href="/drivers" className="modal-grid-succes-text-a">
              Вернуться в раздел "Водители"
            </a>
          </Box>
        </Modal>

        {/* Выдвижная панель для печати договора */}
        <Drawer
          anchor="right"
          open={printDrawerOpen}
          onClose={() => setPrintDrawerOpen(false)}
        >
          <Box sx={drawerStyle}>
            <Typography
              variant="h6"
              component="div"
              style={{ marginBottom: "20px" }}
            >
              Создание договора
            </Typography>

            {showDoneMessage && (
              <Alert severity="success" style={{ marginBottom: "15px" }}>
                Данные для договора скопированы в буфер обмена. Шаблон договора
                скачан.
              </Alert>
            )}

            <div style={{ marginBottom: "20px" }}>
              <FormControl fullWidth style={{ marginBottom: "15px" }}>
                <InputLabel id="driver-select-label">Водитель</InputLabel>
                <Select
                  labelId="driver-select-label"
                  id="driver-select"
                  name="driverId"
                  value={printData.driverId}
                  onChange={handlePrintDataChange}
                  label="Водитель"
                >
                  {drivers.map((driver) => (
                    <MenuItem key={driver.id} value={driver.id}>
                      {driver.surname} {driver.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginBottom: "15px" }}>
                <InputLabel id="car-select-label">Автомобиль</InputLabel>
                <Select
                  labelId="car-select-label"
                  id="car-select"
                  name="carId"
                  value={printData.carId}
                  onChange={handlePrintDataChange}
                  label="Автомобиль"
                >
                  {cars.map((car) => (
                    <MenuItem key={car.id} value={car.id}>
                      {car.gos || "Госномер не указан"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginBottom: "15px" }}>
                <InputLabel id="carrier-select-label">Перевозчик</InputLabel>
                <Select
                  labelId="carrier-select-label"
                  id="carrier-select"
                  name="carrierId"
                  value={printData.carrierId}
                  onChange={handlePrintDataChange}
                  label="Перевозчик"
                >
                  {carriers.map((carrier) => (
                    <MenuItem key={carrier.id} value={carrier.id}>
                      {carrier.name || "Название не указано"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <p>Срок действия договора</p>
              <div className="grid-form-template">
                <TextField
                  fullWidth
                  label="Дата начала"
                  type="date"
                  name="startDate"
                  value={printData.startDate}
                  onChange={handlePrintDataChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ marginBottom: "5px" }}
                />

                <TextField
                  fullWidth
                  label="Дата окончания"
                  type="date"
                  name="endDate"
                  value={printData.endDate}
                  onChange={handlePrintDataChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ marginBottom: "5px" }}
                />
              </div>
              <p>Условия аренды</p>
              <div className="grid-form-template">
                <TextField
                  fullWidth
                  label="Сумма аренды"
                  type="number"
                  name="rentalAmount"
                  value={printData.rentalAmount}
                  onChange={handlePrintDataChange}
                  style={{ marginBottom: "5px" }}
                />

                <TextField
                  fullWidth
                  label="Депозит"
                  type="number"
                  name="deposit"
                  value={printData.deposit}
                  onChange={handlePrintDataChange}
                  style={{ marginBottom: "5px" }}
                />
              </div>
            </div>

            <Button
              variant="contained"
              onClick={handlePrintContract}
              startIcon={
                isPrinting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <DownloadIcon />
                )
              }
              fullWidth
              disabled={
                isPrinting ||
                !printData.driverId ||
                !printData.carId ||
                !printData.carrierId ||
                !printData.startDate ||
                !printData.endDate
              }
            >
              {isPrinting ? "Формирование..." : "Скачать и скопировать данные"}
            </Button>

            {!showDoneMessage && (
              <Typography
                variant="body2"
                style={{ marginTop: "15px", color: "#666" }}
              >
                Данные для договора будут скопированы в буфер обмена, а шаблон
                договора будет скачан.
              </Typography>
            )}
          </Box>
        </Drawer>
      </div>
    </div>
  );
};

export default Drivers;
