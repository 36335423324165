import {
  Box,
  Breadcrumbs,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  IconButton,
  Slide,
} from "@mui/material";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  addDoc,
} from "firebase/firestore";
import { React, useEffect, useState, forwardRef } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import { db } from "../../firebase-config";
import Header from "../../Header";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";

// Transition component for the slide-in effect
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Carriers = () => {
  const carriersCollectionRef = collection(db, "carriers");
  const [carriers, setCarriers] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  // Form state
  const [formData, setFormData] = useState({
    name: "",
    typeCompany: "ООО",
    address: "",
    inn: "",
    ogrn: "",
    bill: "",
    bank: "",
    bikBank: "",
    urAdressBank: "",
  });

  useEffect(() => {
    const getCarriers = async () => {
      const data = await getDocs(query(carriersCollectionRef, orderBy("name")));
      setCarriers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    getCarriers();
  }, []);

  const deleteCarrier = async (id) => {
    try {
      const carrierDoc = doc(db, "carriers", id);
      await deleteDoc(carrierDoc);

      // Обновляем список после удаления
      const updatedCarriers = carriers.filter((carrier) => carrier.id !== id);
      setCarriers(updatedCarriers);
    } catch (error) {
      console.error("Ошибка при удалении:", error);
      alert("Ошибка при удалении: " + error.message);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    // Reset form data
    setFormData({
      name: "",
      typeCompany: "ООО",
      address: "",
      inn: "",
      ogrn: "",
      bill: "",
      bank: "",
      bikBank: "",
      urAdressBank: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(carriersCollectionRef, formData);

      // Refresh the carriers list
      const data = await getDocs(query(carriersCollectionRef, orderBy("name")));
      setCarriers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

      // Close the modal
      handleCloseModal();
    } catch (error) {
      console.error("Ошибка при добавлении:", error);
      alert("Ошибка при добавлении: " + error.message);
    }
  };

  return (
    <div className="grid">
      <Header />
      <div className="content">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <a
              className="breadcrumbs-first"
              href="/"
              underline="hover"
              color="inherit"
            >
              Дашборд
            </a>
            <Typography color="text.primary">
              <b>Собственники</b>
            </Typography>
          </Breadcrumbs>
        </div>
        <p className="header-mt">Собственники</p>

        <div className="info-active-block-table">
          <Button
            variant="outlined"
            size="small"
            className="btn-add-to-table"
            onClick={handleOpenModal}
            style={{ marginRight: "10px" }}
          >
            Добавить
          </Button>
          <Button
            variant="outlined"
            size="small"
            className="btn-settings-table"
          >
            <SettingsIcon className="icon-setting" />
          </Button>
          <div className="drivers-block-btn"></div>
        </div>

        <table id="myTable">
          <thead>
            <tr>
              <th>Название</th>
              <th>Адрес</th>
              <th>Действия</th>
            </tr>
          </thead>
          <tbody>
            {carriers.map((carrier) => (
              <tr key={carrier.id}>
                <td>{carrier.name}</td>
                <td>{carrier.address}</td>
                <td className="td-table-end-action">
                  <Link to={`/carriers_view/${carrier.id}`} value={carrier.id}>
                    <Button
                      variant="none"
                      size="small"
                      className="btn-icon-table"
                    >
                      <VisibilityIcon className="icon-table" />
                    </Button>
                  </Link>
                  <Button
                    variant="none"
                    size="small"
                    className="btn-icon-table"
                    onClick={() => {
                      deleteCarrier(carrier.id);
                    }}
                  >
                    <DeleteOutlineIcon className="icon-table" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Sliding Modal */}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openModal}
        onClose={handleCloseModal}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            position: "fixed",
            right: 0,
            margin: 0,
            height: "100%",
            maxHeight: "100%",
            borderRadius: 0,
            width: "400px",
            maxWidth: "100%",
          },
        }}
      >
        <Box sx={{ p: 3, height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Typography variant="h6">Добавить собственника</Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <form onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <TextField
                required
                label="Название"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />

              <FormControl fullWidth margin="normal">
                <InputLabel>Тип компании</InputLabel>
                <Select
                  name="typeCompany"
                  value={formData.typeCompany}
                  onChange={handleInputChange}
                  label="Тип компании"
                >
                  <MenuItem value="Индивидуальный Предприниматель">
                    Индивидуальный Предприниматель
                  </MenuItem>
                  <MenuItem value="ООО">ООО</MenuItem>
                </Select>
              </FormControl>

              <TextField
                required
                label="Адрес"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />

              <TextField
                label="ИНН"
                name="inn"
                value={formData.inn}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />

              <TextField
                label="ОГРН"
                name="ogrn"
                value={formData.ogrn}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />

              <TextField
                label="Расчетный счет"
                name="bill"
                value={formData.bill}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />

              <TextField
                label="Банк"
                name="bank"
                value={formData.bank}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Бик Банка"
                name="bikBank"
                value={formData.bikBank}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Юридический адрес банка"
                name="urAdressBank"
                value={formData.urAdressBank}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                Сохранить
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>
    </div>
  );
};

export default Carriers;
