import React, { Suspense, lazy, useContext } from "react";
import "./App.css";
import Content from "./Content";
import Drivers from "./screens/Drivers/Drivers";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AutoList from "./screens/Automotive/AutoList";
import AddAuto from "./screens/Automotive/addAuto";
import Login from "./screens/login-page/Login";
import { AuthContext, AuthContextProvider } from "./context/AuthContext";
import ViewAuto from "./screens/Automotive/viewAuto";
import TasksList from "./screens/Tasks/TasksList";
import Settings from "./screens/Settings/Settings";
import RepairList from "./screens/Repair/Repair";
import RepairSettings from "./screens/Repair/RepairSetings";
import UpdateAuto from "./screens/Automotive/updateAuto";
import CalendarTO from "./screens/Repair/CalendarTO";
import ViewRepair from "./screens/Repair/viewRepair";
import UpdateRepair from "./screens/Repair/updateRepair";
import DtpList from "./screens/DTP/Dtp";
import TasksSettings from "./screens/Tasks/TasksSetings";
import AddDriver from "./screens/Drivers/addDriver";
import DamageList from "./screens/Damage/DamageList";
import AddDamage from "./screens/Damage/AddDamage";
import DamageView from "./screens/Damage/DamageView";
import Sidebar from "./Sidebar";
import Header from "./Header";
import AddDeposit from "./screens/Damage/AddDeposit";
import DepositView from "./screens/Damage/DepositView";
import DepositList from "./screens/Damage/DepositList";
import Disk from "./screens/Disk/Disk";
import CreateWaybill from "./screens/CreateWaybill/CreateWaybill";
import DriverDetails from "./screens/Drivers/DriverDetails";
import Carriers from "./screens/Carriers/Carriers";

function App() {
  const { currentUser } = useContext(AuthContext);

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };

  return (
    <AuthContextProvider>
      <BrowserRouter>
        <Sidebar />
        <Suspense
          fallback={<div>Loading...</div>}
          onError={(error) => console.log("Ошибка при загрузке модуля:", error)}
        >
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Content />
                </RequireAuth>
              }
            />
            {/* Задачи */}
            <Route
              path="/tasks"
              element={
                <RequireAuth>
                  <TasksList />
                </RequireAuth>
              }
            />
            <Route
              path="/tasks_settings"
              element={
                <RequireAuth>
                  <TasksSettings />
                </RequireAuth>
              }
            />
            {/* Повреждения */}
            <Route
              path="/damage"
              element={
                <RequireAuth>
                  <DamageList />
                </RequireAuth>
              }
            />
            <Route
              path="/add_damage"
              element={
                <RequireAuth>
                  <AddDamage />
                </RequireAuth>
              }
            />
            <Route
              path="/damage_view/:id"
              element={
                <RequireAuth>
                  <DamageView />
                </RequireAuth>
              }
            />
            <Route
              path="/deposit_view/:id"
              element={
                <RequireAuth>
                  <DepositView />
                </RequireAuth>
              }
            />
            {/* Депозиты */}
            <Route
              path="/add_deposit"
              element={
                <RequireAuth>
                  <AddDeposit />
                </RequireAuth>
              }
            />
            <Route
              path="/deposits"
              element={
                <RequireAuth>
                  <DepositList />
                </RequireAuth>
              }
            />
            {/* Ремонты */}
            <Route
              path="/repair"
              element={
                <RequireAuth>
                  <RepairList />
                </RequireAuth>
              }
            />
            <Route
              path="/repair_view/:id"
              element={
                <RequireAuth>
                  <ViewRepair />
                </RequireAuth>
              }
            />
            <Route
              path="/repair_update/:id"
              element={
                <RequireAuth>
                  <UpdateRepair />
                </RequireAuth>
              }
            />
            <Route
              path="/repair_settings"
              element={
                <RequireAuth>
                  <RepairSettings />
                </RequireAuth>
              }
            />
            <Route
              path="/calendar_maintenance"
              element={
                <RequireAuth>
                  <CalendarTO />
                </RequireAuth>
              }
            />
            {/* Автомобили */}
            <Route
              path="/auto"
              element={
                <RequireAuth>
                  <AutoList />
                </RequireAuth>
              }
            />
            <Route
              path="/auto_view/:id"
              element={
                <RequireAuth>
                  <ViewAuto />
                </RequireAuth>
              }
            />
            <Route
              path="/auto_update/:id"
              element={
                <RequireAuth>
                  <UpdateAuto />
                </RequireAuth>
              }
            />
            <Route
              path="/addauto"
              element={
                <RequireAuth>
                  <AddAuto />
                </RequireAuth>
              }
            />
            {/* Водители */}
            <Route
              path="/drivers"
              element={
                <RequireAuth>
                  <Drivers />
                </RequireAuth>
              }
            />
            <Route
              path="/add_drivers"
              element={
                <RequireAuth>
                  <AddDriver />
                </RequireAuth>
              }
            />
            <Route
              path="/drivers_view/:id"
              element={
                <RequireAuth>
                  <DriverDetails />
                </RequireAuth>
              }
            />
            {/* ДТП */}
            <Route
              path="/dtp"
              element={
                <RequireAuth>
                  <DtpList />
                </RequireAuth>
              }
            />
            <Route
              path="/settings"
              element={
                <RequireAuth>
                  <Settings />
                </RequireAuth>
              }
            />
            <Route
              path="/disk"
              element={
                <RequireAuth>
                  <Disk />
                </RequireAuth>
              }
            />
            <Route
              path="/createWaybill"
              element={
                <RequireAuth>
                  <CreateWaybill />
                </RequireAuth>
              }
            />
            <Route
              path="/carriers"
              element={
                <RequireAuth>
                  <Carriers />
                </RequireAuth>
              }
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </AuthContextProvider>
  );
}

export default App;
