import React, { useState, useEffect } from "react";
import "../../App.css";
import { db, storage } from "../../firebase-config"; // Импортируем Storage
import { collection, getDocs, addDoc, Timestamp } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"; // Импортируем Firebase Storage функции
import {
  Button,
  Modal,
  Select,
  Typography,
  MenuItem,
  TextareaAutosize,
  Breadcrumbs,
  Link,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import Header from "../../Header";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";

const AddDamage = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [patronymic, setPatronymic] = useState("");

  const [driver, setDriver] = useState("");
  const [guilt, setGuilt] = useState("");
  const [typeDamage, setTypeDamage] = useState("");
  const [comment, setComment] = useState("");
  const [amount, setAmount] = useState("");
  const [classeDamage, setClasseDamage] = useState(""); // Новый стейт для classeDamage
  const [dateDamage, setDateDamage] = useState(new Date().toISOString()); // Новый стейт для даты повреждения
  const [dateStartDamage, setDateStartDamage] = useState(
    new Date().toISOString()
  ); // Новый стейт для даты происшествия
  const [dateOsmotr, setDateOsmotr] = useState(""); // Новый стейт для даты осмотра
  const [priceViplati, setPriceViplati] = useState(""); // Новый стейт для суммы выплаты
  const [files, setFiles] = useState([]); // Состояние для загружаемых файлов

  const handleChangeGuilt = (event) => {
    setGuilt(event.target.value);
  };

  const handleChangeTypeDamage = (event) => {
    setTypeDamage(event.target.value);
  };

  const handleChangeClasseDamage = (event) => {
    setClasseDamage(event.target.value);
  };

  const handleFileChange = (event) => {
    setFiles(event.target.files); // Сохраняем выбранные файлы
  };

  const [auto, setAuto] = useState([]); // Массив объектов автомобилей
  const [selectedAutoId, setSelectedAutoId] = useState(""); // Выбранный идентификатор автомобиля
  useEffect(() => {
    const getAuto = async () => {
      const autoCollectionRef = collection(db, "auto");
      const querySnapshot = await getDocs(autoCollectionRef);
      const autoData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAuto(autoData);
    };
    getAuto();
  }, []);

  const handleChangeCar = (event) => {
    setSelectedAutoId(event.target.value);
  };

  const damageCollectionRef = collection(db, "damages");

  const uploadFiles = async (uid, dateStart) => {
    const promises = [];
    Array.from(files).forEach((file) => {
      const filePath = `damages/${uid}/${dateStart}/${file.name}`;
      const storageRef = ref(storage, filePath);
      const uploadTask = uploadBytesResumable(storageRef, file);

      promises.push(
        uploadTask.then(() => getDownloadURL(uploadTask.snapshot.ref))
      );
    });

    return Promise.all(promises);
  };

  const createDamages = async () => {
    const timestamp = Timestamp.fromDate(new Date(dateDamage)); // Преобразуем дату в timestamp
    const startDamageTimestamp = Timestamp.fromDate(new Date(dateStartDamage)); // Преобразуем дату происшествия в timestamp

    const data = {
      typeDamage: typeDamage,
      guilt: guilt,
      auto: selectedAutoId,
      driver: driver,
      amount: amount,
      comment: comment,
      classeDamage: classeDamage,
      dateDamage: timestamp, // Добавляем поле dateDamage в формате timestamp
      dateStartDamage: startDamageTimestamp, // Добавляем поле dateStartDamage
    };

    const docRef = await addDoc(damageCollectionRef, data);

    if (classeDamage === "Страховой") {
      const uid = docRef.id;
      const dateStart = dateStartDamage.split("T")[0]; // Используем дату происшествия как часть пути
      const fileURLs = await uploadFiles(uid, dateStart);
      console.log("Files uploaded to:", fileURLs);
    }

    handleOpen();
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className="grid">
      <Header />
      <div className="content">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Дашборд
            </Link>
            <Link underline="hover" color="inherit" href="/damage">
              ДТП/Повреждение
            </Link>
            <Typography color="text.primary">
              <b>Новое ДТП-Повреждение</b>
            </Typography>
          </Breadcrumbs>
        </div>
        <p className="header-mt">Добавление ДТП / Повреждение</p>
        <div className="info-active-block-table">
          <Button
            variant="outlined"
            size="small"
            onClick={createDamages}
            className="btn-save-to-table"
          >
            Сохранить
          </Button>
          <Button
            variant="outlined"
            size="small"
            href="/auto"
            className="btn-back-to-table"
          >
            Отменить
          </Button>
        </div>
        <div className="box-block-edit-params">
          <div className="box-create-form">
            <Grid container spacing={2} className="grid-form-add flex-center">
              <Grid item xs={2}>
                <p className="text-form">Класс повреждения:</p>
              </Grid>
              <Grid item xs={3}>
                <Select
                  labelId="classe-damage-select-label"
                  id="classe-damage-select"
                  value={classeDamage}
                  onChange={handleChangeClasseDamage}
                  className="select-add-techinsp"
                >
                  <MenuItem value={"Страховой"}>Страховой</MenuItem>
                  <MenuItem value={"Не страховой"}>Не страховой</MenuItem>
                </Select>
              </Grid>
            </Grid>

            {/* Поле для даты происшествия */}
            <Grid container spacing={2} className="grid-form-add flex-center">
              <Grid item xs={2}>
                <p className="text-form">Дата происшествия:</p>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="dateStartDamage"
                  type="date"
                  value={dateStartDamage.split("T")[0]}
                  onChange={(e) => setDateStartDamage(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>

            {/* Поля, которые остаются всегда */}
            <Grid container spacing={2} className="grid-form-add flex-center">
              <Grid item xs={2}>
                <p className="text-form">Тип повреждения:</p>
              </Grid>
              <Grid item xs={3}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={typeDamage}
                  onChange={handleChangeTypeDamage}
                  className="select-add-techinsp"
                >
                  <MenuItem value={"ДТП"}>ДТП</MenuItem>
                  <MenuItem value={"Повреждение"}>Повреждение</MenuItem>
                  <MenuItem value={"Суд"}>Судебное дело</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add flex-center">
              <Grid item xs={2}>
                <p className="text-form">Виновность:</p>
              </Grid>
              <Grid item xs={3}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={guilt}
                  onChange={handleChangeGuilt}
                  className="select-add-techinsp"
                >
                  <MenuItem value={"Виноват"}>Виноват</MenuItem>
                  <MenuItem value={"Не виноват"}>Не виноват</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add flex-center">
              <Grid item xs={2}>
                <p className="text-form">Водитель:</p>
              </Grid>
              <Grid item xs={3}>
                <input
                  className="input-damage"
                  type="text"
                  value={driver}
                  onChange={(event) => {
                    setDriver(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-form">Автомобиль:</p>
              </Grid>
              <Grid item xs={3}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedAutoId}
                  onChange={handleChangeCar}
                  className="select-add-techinsp"
                >
                  {auto.map((item) => (
                    <MenuItem key={item.id} value={item.gos}>
                      {item.gos}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-form">Общая сумма ущерба:</p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-damage"
                  type="number"
                  value={amount}
                  onChange={(event) => {
                    setAmount(event.target.value);
                  }}
                />
              </Grid>
            </Grid>

            {/* Дополнительные поля, если выбран "Страховой" */}
            {classeDamage === "Страховой" && (
              <>
                <Grid container spacing={2} className="grid-form-add">
                  <Grid item xs={2}>
                    <p className="text-form">Дата осмотра:</p>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="dateOsmotr"
                      type="date"
                      value={dateOsmotr}
                      onChange={(e) => setDateOsmotr(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="grid-form-add">
                  <Grid item xs={2}>
                    <p className="text-form">Сумма выплаты СК:</p>
                  </Grid>
                  <Grid item xs={2}>
                    <input
                      className="input-damage"
                      type="number"
                      value={priceViplati}
                      onChange={(e) => setPriceViplati(e.target.value)}
                    />
                  </Grid>
                </Grid>

                {/* Форма для загрузки файлов */}
                <Grid container spacing={2} className="grid-form-add">
                  <Grid item xs={2}>
                    <p className="text-form">Загрузка файлов:</p>
                  </Grid>
                  <Grid item xs={3}>
                    <input
                      type="file"
                      multiple
                      onChange={handleFileChange} // Обрабатываем изменение файла
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <p className="text-form">Комментарий повреждений:</p>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={5}>
                <TextareaAutosize
                  id="comment"
                  className="textArea_bodyText_tech"
                  value={comment}
                  onChange={(event) => {
                    setComment(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style} className="box-modal-succes">
            <Typography
              className="modal-grid-succes"
              id="keep-mounted-modal-description"
              sx={{ mt: 2 }}
            >
              <MinorCrashIcon className="modal-grid-succes-icon" />
              <p className="modal-grid-succes-text">Водитель добавлен!</p>
            </Typography>
            <a href="/auto" className="modal-grid-succes-text-a">
              Вернуться в раздел Автомобили
            </a>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default AddDamage;
