import React, { useState } from "react";
import "../../App.css";
import { db } from "../../firebase-config";
import { collection, addDoc } from "firebase/firestore";
import {
  Button,
  Typography,
  Breadcrumbs,
  Link,
  Box,
  Grid,
  Modal,
} from "@mui/material";
import Header from "../../Header";
import NoCrashIcon from "@mui/icons-material/NoCrash";

const AddDriver = () => {
  // Состояние для хранения всех данных формы
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    patronymic: "",
    phone: "",
    birthdate: "",
    birthplace: "",
    passportNumber: "",
    issuedBy: "",
    issueDate: "",
    divisionCode: "",
    registration: "",
    licenseNumber: "",
    licenseStartDate: "",
    licenseEndDate: "",
    addressRegistered: "",
    addressResidential: "",
    additionalInfo: "",
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const driversCollectionRef = collection(db, "drivers");

  // Функция для обработки изменений в полях формы
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Функция для создания водителя
  const createDriver = async () => {
    try {
      await addDoc(driversCollectionRef, formData);
      handleOpen();
    } catch (error) {
      alert("Ошибка загрузки: " + error.message);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className="grid">
      <Header />
      <div className="content">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Дашборд
            </Link>
            <Link underline="hover" color="inherit" href="/auto">
              Автомобили
            </Link>
            <Typography color="text.primary">
              <b>Добавление водителя</b>
            </Typography>
          </Breadcrumbs>
        </div>
        <p className="header-mt">Добавление водителя</p>
        <div className="info-active-block-table">
          <Button
            variant="outlined"
            size="small"
            onClick={createDriver}
            className="btn-save-to-table"
          >
            Сохранить
          </Button>
          <Button
            variant="outlined"
            size="small"
            href="/auto"
            className="btn-back-to-table"
          >
            Отменить
          </Button>
          <div className="drivers-block-btn"></div>
        </div>
        <div className="box-block-edit-params">
          <div className="box-create-form">
            {/* Личные данные */}
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={1}>
                <p className="text-input-update-auto">Фамилия:</p>
              </Grid>
              <Grid item xs={3}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="surname"
                  value={formData.surname}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={1}>
                <p className="text-input-update-auto">Имя:</p>
              </Grid>
              <Grid item xs={3}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={1}>
                <p className="text-input-update-auto">Отчество:</p>
              </Grid>
              <Grid item xs={3}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="patronymic"
                  value={formData.patronymic}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={1}>
                <p className="text-input-update-auto">Телефон:</p>
              </Grid>
              <Grid item xs={3}>
                <input
                  className="input-update-auto"
                  type="number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

            {/* Водительское удостоверение */}
            <p className="p-big-update">Вод.удостоверение:</p>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">
                  Номер водительского удостоверения:
                </p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="licenseNumber"
                  value={formData.licenseNumber}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">
                  Дата начала действия:
                </p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="date"
                  name="licenseStartDate"
                  value={formData.licenseStartDate}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">
                  Дата завершения действия:
                </p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="date"
                  name="licenseEndDate"
                  value={formData.licenseEndDate}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

            {/* Паспортные данные */}
            <p className="p-big-update">Паспорт:</p>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">Серия / номер:</p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="passportNumber"
                  value={formData.passportNumber}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">Кем выдан:</p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="issuedBy"
                  value={formData.issuedBy}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">Дата выдачи:</p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="date"
                  name="issueDate"
                  value={formData.issueDate}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">
                  Код подразделения:
                </p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="divisionCode"
                  value={formData.divisionCode}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

            {/* Адреса */}
            <p className="p-big-update">Адреса:</p>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">
                  Прописан по адресу:
                </p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="addressRegistered"
                  value={formData.addressRegistered}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">
                  Место проживания:
                </p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="addressResidential"
                  value={formData.addressResidential}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">
                  Доп.информация:
                </p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="additionalInfo"
                  value={formData.additionalInfo}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style} className="box-modal-succes">
            <Typography
              className="modal-grid-succes"
              id="keep-mounted-modal-description"
              sx={{ mt: 2 }}
            >
              <NoCrashIcon className="modal-grid-succes-icon" />
              <p className="modal-grid-succes-text">Водитель добавлен!</p>
            </Typography>
            <a href="/auto" className="modal-grid-succes-text-a">
              Вернуться в раздел Автомобили
            </a>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default AddDriver;
