import React, { useState, useEffect } from "react";
import "../../App.css";
import { db } from "../../firebase-config";
import { collection, getDocs, addDoc } from "firebase/firestore";
import { 
    Button, 
    Typography, 
    Grid, 
    MenuItem, 
    Select, 
    TextField,
    Drawer,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from "@mui/material";
import { saveAs } from "file-saver";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import Header from "../../Header";
import CloseIcon from "@mui/icons-material/Close";

const CreateWaybill = () => {
    const [drivers, setDrivers] = useState([]);
    const [cars, setCars] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState("");
    const [selectedCar, setSelectedCar] = useState("");
    const [selectedCarrier, setSelectedCarrier] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [carriers, setCarriers] = useState([]);
    const [showAddForm, setShowAddForm] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [newCarrier, setNewCarrier] = useState({
        name: '',
        address: '',
        inn: ''
    });

    useEffect(() => {
        const fetchDrivers = async () => {
            const data = await getDocs(collection(db, "drivers"));
            setDrivers(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        };
    
        const fetchCars = async () => {
            const data = await getDocs(collection(db, "auto"));
            const carsData = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            console.log("Загруженные данные авто:", carsData);
            setCars(carsData);
        };

        const fetchCarriers = async () => {
            const data = await getDocs(collection(db, "carriers"));
            setCarriers(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        };
    
        fetchDrivers();
        fetchCars();
        fetchCarriers();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewCarrier(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleAddCarrier = async () => {
        try {
            await addDoc(collection(db, "carriers"), newCarrier);
            
            // Обновляем список перевозчиков
            const data = await getDocs(collection(db, "carriers"));
            setCarriers(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
            
            // Очищаем форму
            setNewCarrier({
                name: '',
                address: '',
                inn: ''
            });
            setShowAddForm(false);
            
            // Показываем сообщение об успехе
            setShowSuccess(true);
            setTimeout(() => {
                setShowSuccess(false);
            }, 3000);
        } catch (error) {
            console.error("Ошибка при добавлении перевозчика:", error);
            alert("Ошибка при добавлении перевозчика");
        }
    };

    const generateDocument = async () => {
        if (!selectedDriver || !selectedCar || !startDate || !endDate || !selectedCarrier) {
            alert("Пожалуйста, заполните все поля!");
            return;
        }
    
        const driver = drivers.find((d) => d.id === selectedDriver);
        const car = cars.find((c) => c.id === selectedCar);
        const carrier = carriers.find((c) => c.id === selectedCarrier);
    
        console.log("Выбранное авто:", car);
        console.log("Все доступные авто:", cars);
        console.log("Выбранный перевозчик:", carrier);
    
        try {
            const templatePath = require('../../templates/waybill_template.docx');
            
            const response = await fetch(templatePath);
            
            if (!response.ok) {
                throw new Error(`Ошибка загрузки шаблона: ${response.status}`);
            }
    
            const arrayBuffer = await response.arrayBuffer();
            
            if (!arrayBuffer || arrayBuffer.byteLength === 0) {
                throw new Error("Получен пустой файл шаблона");
            }
    
            const zip = new PizZip(arrayBuffer);
            const doc = new Docxtemplater(zip, {
                paragraphLoop: true,
                linebreaks: true
            });
    
            doc.setData({
                name: driver.name,
                surname: driver.surname,
                patronymic: driver.patronymic,
                nameFirst: driver.name ? driver.name.charAt(0) + '.' : '',
                patronymicFirst: driver.patronymic ? driver.patronymic.charAt(0) + '.' : '',
                phone: driver.phone,
                licenseNumber: driver.licenseNumber,
                marka: car.marka,
                model: car.model,
                gos: car.gos,
                carColor: car.color,
                startDate,
                endDate,
                carrierName: carrier.name,
                carrierAddress: carrier.address,
                carrierInn: carrier.inn
            });
    
            doc.render();
            
            const generatedBlob = doc.getZip().generate({
                type: "blob",
                mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            });
            
            saveAs(generatedBlob, "Путевой_лист.docx");
            
        } catch (error) {
            console.error("Ошибка при генерации документа:", error);
            alert(`Ошибка при генерации документа: ${error.message}`);
        }
    };

    return (
        <div className="grid">
            <Header />
            <div className="content">
                <Typography variant="h5" className="header-mt">Создание путевого листа</Typography>

                <Button 
                    variant="outlined"
                    onClick={() => setDrawerOpen(true)}
                    style={{ position: 'absolute', right: '20px', top: '80px' }}
                >
                    Настройка сведений о перевозчике
                </Button>

                <div className="box-block-edit-params">
                    <Grid container spacing={2} className="grid-form-add">
                        <Grid item xs={2}><p className="text-input-update-auto">Выберите водителя:</p></Grid>
                        <Grid item xs={4}>
                            <Select 
                                className="input-update-auto" 
                                value={selectedDriver} 
                                onChange={(e) => setSelectedDriver(e.target.value)} 
                                fullWidth
                            >
                                {drivers.map((driver) => (
                                    <MenuItem key={driver.id} value={driver.id}>
                                        {driver.surname} {driver.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className="grid-form-add">
                        <Grid item xs={2}><p className="text-input-update-auto">Выберите автомобиль:</p></Grid>
                        <Grid item xs={4}>
                            <Select 
                                className="input-update-auto" 
                                value={selectedCar} 
                                onChange={(e) => setSelectedCar(e.target.value)} 
                                fullWidth
                            >
                                {cars.map((car) => (
                                    <MenuItem key={car.id} value={car.id}>
                                        {car.marka} {car.model} ({car.gos})
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className="grid-form-add">
                        <Grid item xs={2}><p className="text-input-update-auto">Выберите перевозчика:</p></Grid>
                        <Grid item xs={4}>
                            <Select 
                                className="input-update-auto" 
                                value={selectedCarrier} 
                                onChange={(e) => setSelectedCarrier(e.target.value)} 
                                fullWidth
                            >
                                {carriers.map((carrier) => (
                                    <MenuItem key={carrier.id} value={carrier.id}>
                                        {carrier.name} (ИНН: {carrier.inn})
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className="grid-form-add">
                        <Grid item xs={2}><p className="text-input-update-auto">Дата начала рейса:</p></Grid>
                        <Grid item xs={4}>
                            <TextField 
                                type="date" 
                                className="input-update-auto" 
                                value={startDate} 
                                onChange={(e) => setStartDate(e.target.value)} 
                                fullWidth 
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className="grid-form-add">
                        <Grid item xs={2}><p className="text-input-update-auto">Дата завершения рейса:</p></Grid>
                        <Grid item xs={4}>
                            <TextField 
                                type="date" 
                                className="input-update-auto" 
                                value={endDate} 
                                onChange={(e) => setEndDate(e.target.value)} 
                                fullWidth 
                            />
                        </Grid>
                    </Grid>

                    <div className="info-active-block-table">
                        <Button 
                            variant="outlined" 
                            size="small" 
                            onClick={generateDocument} 
                            className="btn-save-to-table"
                        >
                            Сгенерировать путевой лист
                        </Button>
                    </div>
                </div>

                <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                    PaperProps={{
                        style: {
                            width: '35%',
                        },
                    }}
                >
                    <div style={{ padding: '20px' }}>
                        <div style={{ 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center', 
                            marginBottom: '20px' 
                        }}>
                            <Typography variant="h6">Сведения о перевозчиках</Typography>
                            <IconButton onClick={() => setDrawerOpen(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>

                        <Button 
                            variant="contained" 
                            color="primary" 
                            style={{ marginBottom: '20px' }}
                            onClick={() => setShowAddForm(!showAddForm)}
                        >
                            Добавить перевозчика
                        </Button>

                        {showSuccess && (
                            <Typography 
                                style={{ 
                                    color: 'green', 
                                    marginBottom: '20px',
                                    padding: '10px',
                                    backgroundColor: '#e8f5e9',
                                    borderRadius: '4px'
                                }}
                            >
                                Перевозчик успешно создан!
                            </Typography>
                        )}

                        {showAddForm && (
                            <div style={{ marginBottom: '20px' }}>
                                <TextField
                                    fullWidth
                                    label="Название перевозчика"
                                    name="name"
                                    value={newCarrier.name}
                                    onChange={handleInputChange}
                                    style={{ marginBottom: '10px' }}
                                />
                                <TextField
                                    fullWidth
                                    label="Адрес"
                                    name="address"
                                    value={newCarrier.address}
                                    onChange={handleInputChange}
                                    style={{ marginBottom: '10px' }}
                                />
                                <TextField
                                    fullWidth
                                    label="ИНН/ОГРНИП"
                                    name="inn"
                                    value={newCarrier.inn}
                                    onChange={handleInputChange}
                                    style={{ marginBottom: '10px' }}
                                />
                                <Button 
                                    variant="contained" 
                                    color="primary"
                                    onClick={handleAddCarrier}
                                >
                                    Сохранить
                                </Button>
                            </div>
                        )}

                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Название перевозчика</TableCell>
                                        <TableCell>Адрес</TableCell>
                                        <TableCell>ИНН/ОГРНИП</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {carriers.map((carrier) => (
                                        <TableRow key={carrier.id}>
                                            <TableCell>{carrier.name}</TableCell>
                                            <TableCell>{carrier.address}</TableCell>
                                            <TableCell>{carrier.inn}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Drawer>
            </div>
        </div>
    );
};

export default CreateWaybill;