import React, { useState, useEffect } from "react";
import "../../App.css";
import { db } from "../../firebase-config";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import Header from "../../Header";
import {
  Grid,
  Button,
  Modal,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Drawer,
  CircularProgress,
  Alert,
} from "@mui/material";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  Table,
  TableRow,
  TableCell,
  BorderStyle,
  WidthType,
  AlignmentType,
} from "docx";

const DriverDetails = () => {
  const [driverData, setDriverData] = useState(null);
  const [editableData, setEditableData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [printDrawerOpen, setPrintDrawerOpen] = useState(false);
  const [cars, setCars] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [showDoneMessage, setShowDoneMessage] = useState(false);
  const [printData, setPrintData] = useState({
    carId: "",
    carrierId: "",
    startDate: "",
    endDate: "",
    rentalAmount: "",
    deposit: "",
  });
  const { id } = useParams();

  // Функция для загрузки данных водителя
  const fetchDriverDetails = async () => {
    try {
      if (!id) {
        throw new Error("ID водителя не указан");
      }

      const driverDocRef = doc(db, "drivers", id);
      const driverDocSnap = await getDoc(driverDocRef);

      if (driverDocSnap.exists()) {
        const data = driverDocSnap.data();
        setDriverData(data);
        setEditableData(data);
      } else {
        throw new Error("Водитель не найден");
      }
    } catch (error) {
      console.error("Ошибка загрузки данных водителя:", error);
      alert("Не удалось загрузить данные водителя: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Функция для загрузки автомобилей
  const fetchCars = async () => {
    try {
      const carsCollection = collection(db, "auto");
      const carsSnapshot = await getDocs(carsCollection);
      const carsList = carsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCars(carsList);
    } catch (error) {
      console.error("Ошибка загрузки автомобилей:", error);
    }
  };

  // Функция для загрузки перевозчиков
  const fetchCarriers = async () => {
    try {
      const carriersCollection = collection(db, "carriers");
      const carriersSnapshot = await getDocs(carriersCollection);
      const carriersList = carriersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCarriers(carriersList);
    } catch (error) {
      console.error("Ошибка загрузки перевозчиков:", error);
    }
  };

  // Функция для обработки изменений в полях
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditableData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Функция для обработки изменений в полях печати
  const handlePrintDataChange = (e) => {
    const { name, value } = e.target;
    setPrintData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Если изменился ID автомобиля или перевозчика, найдем соответствующий объект
    if (name === "carId") {
      const car = cars.find((c) => c.id === value);
      setSelectedCar(car);
    } else if (name === "carrierId") {
      const carrier = carriers.find((c) => c.id === value);
      setSelectedCarrier(carrier);
    }
  };

  // Функция для сохранения изменений
  const handleSave = async () => {
    try {
      const driverDocRef = doc(db, "drivers", id);
      await updateDoc(driverDocRef, editableData);

      // Обновляем состояние
      setDriverData(editableData);
      setIsEditing(false);
      setOpenSuccessModal(true);
    } catch (error) {
      console.error("Ошибка сохранения данных:", error);
      alert("Не удалось сохранить изменения: " + error.message);
    }
  };

  // Функция для отмены редактирования
  const handleCancelEdit = () => {
    setEditableData(driverData);
    setIsEditing(false);
  };

  // Функция для открытия панели печати
  const handleOpenPrintDrawer = () => {
    setPrintDrawerOpen(true);
    fetchCars();
    fetchCarriers();
    setShowDoneMessage(false);
  };

  // Функция для формирования текста с данными для вставки в договор
  // Функция для формирования текста с данными для вставки в договор
  const generateContractData = () => {
    if (!driverData || !selectedCar || !selectedCarrier) return "";

    // Форматирование даты
    const formatDate = (dateString) => {
      if (!dateString) return "";
      const date = new Date(dateString);
      return date.toLocaleDateString("ru-RU", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    };

    // Форматирование ФИО
    const driverFullName = `${driverData.surname || ""} ${
      driverData.name || ""
    } ${driverData.patronymic || ""}`.trim();

    return `
  === ДАННЫЕ ДЛЯ ДОГОВОРА ===
  
  ВОДИТЕЛЬ:
  ФИО: ${driverFullName}
  Паспорт: ${driverData.passportNumber || ""}
  Выдан: ${driverData.issuedBy || ""} ${formatDate(driverData.issueDate) || ""}
  Код подразделения: ${driverData.divisionCode || ""}
  Адрес: ${driverData.addressRegistered || ""}
  Телефон: ${driverData.phone || ""}
  Вод. удостоверение: ${driverData.licenseNumber || ""}
  
  АВТОМОБИЛЬ:
  Гос. номер: ${selectedCar.gos || ""}
  Модель: ${selectedCar.model || ""}
  VIN: ${selectedCar.vin || ""}
  
  ПЕРЕВОЗЧИК:
  Название: ${selectedCarrier.name || ""}
  
  УСЛОВИЯ ДОГОВОРА:
  Дата начала: ${formatDate(printData.startDate)}
  Дата окончания: ${formatDate(printData.endDate)}
  Сумма аренды: ${printData.rentalAmount || "0"} руб.
  Депозит: ${printData.deposit || "0"} руб.
  `;
  };

  // Функция для скачивания шаблона договора
  // Функция для скачивания шаблона договора

  // Функция для скачивания шаблона договора
  // Функция для скачивания шаблона договора
  const handlePrintContract = async () => {
    try {
      setIsPrinting(true);

      // Проверка наличия обязательных данных
      if (
        !printData.carId ||
        !printData.carrierId ||
        !printData.startDate ||
        !printData.endDate
      ) {
        throw new Error("Пожалуйста, заполните все обязательные поля");
      }

      // Копируем данные для вставки в буфер обмена
      const contractData = generateContractData();
      await navigator.clipboard.writeText(contractData);

      // Скачиваем и обрабатываем шаблон договора
      try {
        // Импортируем шаблон напрямую через require, как в рабочем компоненте CreateWaybill
        const templatePath = require("../../templates/new.docx");

        const response = await fetch(templatePath);

        if (!response.ok) {
          throw new Error(`Ошибка загрузки шаблона (${response.status})`);
        }

        // Получаем файл как ArrayBuffer вместо Blob
        const arrayBuffer = await response.arrayBuffer();

        if (!arrayBuffer || arrayBuffer.byteLength === 0) {
          throw new Error("Получен пустой файл шаблона");
        }

        // Форматирование полного ФИО
        const driverFullName = `${driverData.surname || ""} ${
          driverData.name || ""
        } ${driverData.patronymic || ""}`.trim();

        // Форматирование даты
        const formatDate = (dateString) => {
          if (!dateString) return "";
          const date = new Date(dateString);
          return date.toLocaleDateString("ru-RU", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });
        };

        // Используем PizZip для обработки docx как zip-архива
        const zip = new PizZip(arrayBuffer);
        const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
        });

        // Заполняем шаблон данными, используя точно такие же имена полей как в шаблоне
        doc.setData({
          // Основные поля из шаблона
          driverFullName: driverFullName,
          carGos: selectedCar?.gos || "",
          carMarka: selectedCar?.marka || "",
          carModel: selectedCar?.model || "",
          carVin: selectedCar?.vin || "",
          carColor: selectedCar?.color || "",
          carYear: selectedCar?.year || "",
          startDate: formatDate(printData.startDate),
          endDate: formatDate(printData.endDate),
          rentalAmount: printData.rentalAmount || "",
          deposit: printData.deposit || "",

          // Дополнительные поля для полноты
          issuedBy: driverData.issuedBy || "",
          divisionCode: driverData.divisionCode || "",
          issueDate: formatDate(driverData.issueDate),
          addressRegistered: driverData.addressRegistered || "",
          phone: driverData.phone || "",
          licenseNumber: driverData.licenseNumber || "",
          carrierName: selectedCarrier?.name || "",
          carrierInn: selectedCarrier?.inn || "",
          carrierAddress: selectedCarrier?.address || "",
          passportNumber: driverData.passportNumber || "",
        });

        // Рендерим документ
        doc.render();

        // Генерируем итоговый файл
        const generatedBlob = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });

        // Сохраняем файл с помощью file-saver
        saveAs(
          generatedBlob,
          `Договор_аренды_${driverData.surname || "водитель"}_${
            driverData.name || ""
          }.docx`
        );

        // Показываем сообщение об успехе
        setShowDoneMessage(true);
      } catch (error) {
        console.error("Ошибка при загрузке шаблона:", error);

        // Добавляем более подробную информацию об ошибке
        if (error.properties && error.properties.errors) {
          console.error(
            "Ошибки шаблона:",
            JSON.stringify(error.properties.errors)
          );
        }

        // Если не удалось обработать docx, сообщаем о необходимости проверить шаблон
        alert(
          `Ошибка при загрузке шаблона: ${error.message}\n\nПроверьте, что файл шаблона не поврежден и находится в правильной директории.`
        );
      }

      setIsPrinting(false);
    } catch (error) {
      console.error("Ошибка при генерации договора:", error);
      alert("Ошибка: " + error.message);
      setIsPrinting(false);
    }
  };

  useEffect(() => {
    fetchDriverDetails();
  }, [id]);

  // Стили для модального окна
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  // Стили для панели печати
  const drawerStyle = {
    width: 350,
    padding: "20px",
  };

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (!driverData) {
    return <div>Водитель не найден</div>;
  }

  return (
    <div className="grid">
      <Header />
      <div className="content">
        <div role="presentation">
          <div aria-label="breadcrumb">
            <a href="/" style={{ textDecoration: "none", color: "inherit" }}>
              Дашборд
            </a>
            {" > "}
            <a
              href="/auto"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Автомобили
            </a>
            {" > "}
            <span style={{ fontWeight: "bold" }}>Информация о водителе</span>
          </div>
        </div>

        <div className="info-active-block-table"></div>

        <p className="header-mt">Информация о водителе</p>
        <div className="info-active-deposit-panel">
          {!isEditing ? (
            <>
              <Button
                variant="outlined"
                size="small"
                onClick={() => setIsEditing(true)}
                className="btn-save-to-table"
                style={{ marginRight: "10px" }}
              >
                Редактировать
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={handleOpenPrintDrawer}
                className="btn-save-to-table"
                startIcon={<PrintIcon />}
              >
                Печать договора
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                size="small"
                onClick={handleSave}
                className="btn-save-to-table"
                style={{ marginRight: "10px" }}
              >
                Сохранить
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={handleCancelEdit}
                className="btn-back-to-table"
              >
                Отменить
              </Button>
            </>
          )}
        </div>

        <div className="box-block-edit-params">
          <div className="box-create-form">
            {/* Личные данные */}
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={1}>
                <p className="text-input-update-auto">Фамилия:</p>
              </Grid>
              <Grid item xs={3}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="surname"
                  value={isEditing ? editableData.surname : driverData.surname}
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={1}>
                <p className="text-input-update-auto">Имя:</p>
              </Grid>
              <Grid item xs={3}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="name"
                  value={isEditing ? editableData.name : driverData.name}
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={1}>
                <p className="text-input-update-auto">Отчество:</p>
              </Grid>
              <Grid item xs={3}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="patronymic"
                  value={
                    isEditing ? editableData.patronymic : driverData.patronymic
                  }
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={1}>
                <p className="text-input-update-auto">Телефон:</p>
              </Grid>
              <Grid item xs={3}>
                <input
                  className="input-update-auto"
                  type="number"
                  name="phone"
                  value={isEditing ? editableData.phone : driverData.phone}
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </Grid>
            </Grid>

            {/* Водительское удостоверение */}
            <p className="p-big-update">Вод.удостоверение:</p>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">
                  Номер водительского удостоверения:
                </p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="licenseNumber"
                  value={
                    isEditing
                      ? editableData.licenseNumber
                      : driverData.licenseNumber
                  }
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">
                  Дата начала действия:
                </p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="date"
                  name="licenseStartDate"
                  value={
                    isEditing
                      ? editableData.licenseStartDate
                      : driverData.licenseStartDate
                  }
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">
                  Дата завершения действия:
                </p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="date"
                  name="licenseEndDate"
                  value={
                    isEditing
                      ? editableData.licenseEndDate
                      : driverData.licenseEndDate
                  }
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </Grid>
            </Grid>

            {/* Паспортные данные */}
            <p className="p-big-update">Паспорт:</p>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">Серия / номер:</p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="passportNumber"
                  value={
                    isEditing
                      ? editableData.passportNumber
                      : driverData.passportNumber
                  }
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">Кем выдан:</p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="issuedBy"
                  value={
                    isEditing ? editableData.issuedBy : driverData.issuedBy
                  }
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">Дата выдачи:</p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="date"
                  name="issueDate"
                  value={
                    isEditing ? editableData.issueDate : driverData.issueDate
                  }
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">
                  Код подразделения:
                </p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="divisionCode"
                  value={
                    isEditing
                      ? editableData.divisionCode
                      : driverData.divisionCode
                  }
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </Grid>
            </Grid>

            {/* Адреса */}
            <p className="p-big-update">Адреса:</p>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">
                  Прописан по адресу:
                </p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="addressRegistered"
                  value={
                    isEditing
                      ? editableData.addressRegistered
                      : driverData.addressRegistered
                  }
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">
                  Место проживания:
                </p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="addressResidential"
                  value={
                    isEditing
                      ? editableData.addressResidential
                      : driverData.addressResidential
                  }
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="grid-form-add">
              <Grid item xs={2}>
                <p className="text-for-input-update-auto-2-2">
                  Доп.информация:
                </p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="input-update-auto"
                  type="text"
                  name="additionalInfo"
                  value={
                    isEditing
                      ? editableData.additionalInfo
                      : driverData.additionalInfo
                  }
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </Grid>
            </Grid>
          </div>
        </div>

        {/* Модальное окно об успешном сохранении */}
        <Modal
          open={openSuccessModal}
          onClose={() => setOpenSuccessModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle} className="box-modal-succes">
            <Typography
              className="modal-grid-succes"
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              <NoCrashIcon className="modal-grid-succes-icon" />
              <p className="modal-grid-succes-text">Данные обновлены!</p>
            </Typography>
            <Button
              onClick={() => setOpenSuccessModal(false)}
              className="modal-grid-succes-text-a"
            >
              OK
            </Button>
          </Box>
        </Modal>

        {/* Выдвижная панель для печати договора */}
        <Drawer
          anchor="right"
          open={printDrawerOpen}
          onClose={() => setPrintDrawerOpen(false)}
        >
          <Box sx={drawerStyle}>
            <Typography
              variant="h6"
              component="div"
              style={{ marginBottom: "20px" }}
            >
              Печать договора
            </Typography>

            {showDoneMessage && (
              <Alert severity="success" style={{ marginBottom: "15px" }}>
                Данные для договора скопированы в буфер обмена. Шаблон договора
                скачан.
              </Alert>
            )}

            <div style={{ marginBottom: "20px" }}>
              <FormControl fullWidth style={{ marginBottom: "15px" }}>
                <InputLabel id="car-select-label">Автомобиль</InputLabel>
                <Select
                  labelId="car-select-label"
                  id="car-select"
                  name="carId"
                  value={printData.carId}
                  onChange={handlePrintDataChange}
                  label="Автомобиль"
                >
                  {cars.map((car) => (
                    <MenuItem key={car.id} value={car.id}>
                      {car.gos || "Госномер не указан"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginBottom: "15px" }}>
                <InputLabel id="carrier-select-label">Перевозчик</InputLabel>
                <Select
                  labelId="carrier-select-label"
                  id="carrier-select"
                  name="carrierId"
                  value={printData.carrierId}
                  onChange={handlePrintDataChange}
                  label="Перевозчик"
                >
                  {carriers.map((carrier) => (
                    <MenuItem key={carrier.id} value={carrier.id}>
                      {carrier.name || "Название не указано"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                fullWidth
                label="Дата начала"
                type="date"
                name="startDate"
                value={printData.startDate}
                onChange={handlePrintDataChange}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ marginBottom: "15px" }}
              />

              <TextField
                fullWidth
                label="Дата окончания"
                type="date"
                name="endDate"
                value={printData.endDate}
                onChange={handlePrintDataChange}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ marginBottom: "15px" }}
              />

              <TextField
                fullWidth
                label="Сумма аренды"
                type="number"
                name="rentalAmount"
                value={printData.rentalAmount}
                onChange={handlePrintDataChange}
                style={{ marginBottom: "15px" }}
              />

              <TextField
                fullWidth
                label="Депозит"
                type="number"
                name="deposit"
                value={printData.deposit}
                onChange={handlePrintDataChange}
                style={{ marginBottom: "15px" }}
              />
            </div>

            <Button
              variant="contained"
              onClick={handlePrintContract}
              startIcon={
                isPrinting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <DownloadIcon />
                )
              }
              fullWidth
              disabled={
                isPrinting ||
                !printData.carId ||
                !printData.carrierId ||
                !printData.startDate ||
                !printData.endDate
              }
            >
              {isPrinting ? "Формирование..." : "Скачать и скопировать данные"}
            </Button>

            {!showDoneMessage && (
              <Typography
                variant="body2"
                style={{ marginTop: "15px", color: "#666" }}
              >
                Данные для договора будут скопированы в буфер обмена, а шаблон
                договора будет скачан.
              </Typography>
            )}
          </Box>
        </Drawer>
      </div>
    </div>
  );
};

export default DriverDetails;
