import {
  Box,
  Breadcrumbs,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { React, useEffect, useState } from "react";
import { useNavigation, Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import "../../App.css";
import { db } from "../../firebase-config";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";
import avatar from "../../assets/ava-driver.png";

const DtpList = () => {
  const usersCollectionRef = collection(db, "drivers");
  const [drivers, setDrivers] = useState([]);
  const [activeTab, setActiveTab] = useState("active");

  const updateUser = async (id, age) => {
    const userDoc = doc(db, "drivers", id);
    const newFields = { age: age + 1 };
    await updateDoc(userDoc, newFields);
  };

  const deleteUser = async (id) => {
    const userDoc = doc(db, "drivers", id);
    await deleteDoc(userDoc);
  };

  useEffect(() => {
    const getUsers = async () => {
      // Query based on status
      const statusQuery = query(
        usersCollectionRef,
        where("status", "==", activeTab),
        orderBy("surname")
      );
      const data = await getDocs(statusQuery);
      setDrivers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    getUsers();
  }, [activeTab]);

  const deleteDriver = async (id) => {
    const userDoc = doc(db, "drivers", id);
    await deleteDoc(userDoc);
    if (deleteDriver) {
      handleOpen();
    } else {
      alert("ошибка загрузки");
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const start = () => {
    if (drivers.value !== "") {
      return (
        <div>
          <p>Нет</p>
        </div>
      );
    } else {
      //Else not set CSS rule
    }
  };

  const date = new Date();

  // Получение всех документов из коллекции "auto"
  const getAllDocuments = async () => {
    const autoCollectionRef = collection(db, "auto");
    const querySnapshot = await getDocs(autoCollectionRef);
    const documents = [];

    querySnapshot.forEach((doc) => {
      documents.push({ id: doc.id, data: doc.data() });
    });

    return documents;
  };

  return (
    <div className="grid">
      <Header />
      <div className="content">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <a
              className="breadcrumbs-first"
              href="/"
              underline="hover"
              color="inherit"
            >
              Дашборд
            </a>
            <Typography color="text.primary">
              <b>ДТП / Ущерб</b>
            </Typography>
          </Breadcrumbs>
        </div>
        <p className="header-mt">ДТП / Ущерб</p>

        <div className="tabs-container rounded-lg bg-white p-2 mb-4 inline-flex">
          <button
            className={`px-4 py-2 rounded-md mr-2 ${
              activeTab === "active"
                ? "bg-gray-100 text-gray-900"
                : "text-gray-500 hover:text-gray-700"
            }`}
            onClick={() => setActiveTab("active")}
          >
            Активные
          </button>
          <button
            className={`px-4 py-2 rounded-md ${
              activeTab === "end"
                ? "bg-gray-100 text-gray-900"
                : "text-gray-500 hover:text-gray-700"
            }`}
            onClick={() => setActiveTab("end")}
          >
            Завершенные
          </button>
        </div>

        <div className="info-active-block-table">
          <Button variant="outlined" size="small" className="btn-add-to-table">
            Добавить
          </Button>
          <Button
            variant="outlined"
            size="small"
            className="btn-settings-table"
          >
            <SettingsIcon className="icon-setting" />
          </Button>
          <div className="drivers-block-btn"></div>
        </div>

        <table id="myTable">
          <tr>
            <th>Фото</th>
            <th>Фамилия</th>
            <th>Имя</th>
            <th>Отчество</th>
            <th>Телефон</th>
            <th>Вод.удост.</th>
            <th>Комментарий</th>
            <th>Действия</th>
          </tr>
          {drivers.map((drivers) => (
            <tr key={drivers.id}>
              <td>
                <img src={avatar} alt="Logo" className="ava-img" />
              </td>
              <td>{drivers.surname}</td>
              <td>{drivers.name}</td>
              <td>{drivers.patronymic}</td>
              <td>{start}</td>
              <td>{drivers.certificate}</td>
              <td>{drivers.certificate}</td>
              <td className="td-table-end-action">
                <Link to={`/drivers_view/${drivers.id}`} value={drivers.id}>
                  <Button
                    variant="none"
                    size="small"
                    className="btn-icon-table"
                  >
                    <VisibilityIcon className="icon-table" />
                  </Button>
                </Link>
                <Link to={`/drivers_update/${drivers.id}`} value={drivers.id}>
                  <Button variant="none" className="btn-icon-table">
                    <EditIcon className="icon-table" />
                  </Button>
                </Link>
                <Button
                  variant="none"
                  size="small"
                  className="btn-icon-table"
                  onClick={() => {
                    deleteDriver(drivers.id);
                  }}
                >
                  <DeleteOutlineIcon className="icon-table" />
                </Button>
              </td>
            </tr>
          ))}
        </table>
        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style} className="box-modal-succes">
            <Typography
              className="modal-grid-succes"
              id="keep-mounted-modal-description"
              sx={{ mt: 2 }}
            >
              <HighlightOffIcon className="modal-grid-delete-icon" />
              <p className="modal-grid-succes-text">Водитель успешно удален!</p>
            </Typography>
            <a href="/drivers" className="modal-grid-succes-text-a">
              Вернуться в раздел "Водители"
            </a>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default DtpList;
